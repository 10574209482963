var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "b-form",
                    {
                      ref: "firstTabForm",
                      on: { submit: _vm.onSubmit, reset: _vm.onReset }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Order Name:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "batchName",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "batchName",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.batchName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchName", $$v)
                              },
                              expression: "form.batchName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Check Batch:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "check-batch",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "check-batch",
                              options: _vm.types.batchOrder,
                              "value-field": "id",
                              "text-field": "batchName",
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeBatchId(_vm.form.orderId)
                              }
                            },
                            model: {
                              value: _vm.form.orderId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "orderId", $$v)
                              },
                              expression: "form.orderId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.form.orderId
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Check Flavor:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "check-flavor",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "checkFlavor", disabled: "" },
                                model: {
                                  value: _vm.flavorName,
                                  callback: function($$v) {
                                    _vm.flavorName = $$v
                                  },
                                  expression: "flavorName"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.orderId
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Check Quantity:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "check-quantity",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "checkQuantity", disabled: "" },
                                model: {
                                  value: _vm.batchQuantity,
                                  callback: function($$v) {
                                    _vm.batchQuantity = $$v
                                  },
                                  expression: "batchQuantity"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.orderId
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Start Scope:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "start-scope",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "startScope", disabled: "" },
                                model: {
                                  value: _vm.startScope,
                                  callback: function($$v) {
                                    _vm.startScope = $$v
                                  },
                                  expression: "startScope"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.orderId
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "End Scope:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "end-scope",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "endScope", disabled: "" },
                                model: {
                                  value: _vm.endScope,
                                  callback: function($$v) {
                                    _vm.endScope = $$v
                                  },
                                  expression: "endScope"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "First Flavor Name:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "firstFlavorName",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "firstFlavorName",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.firstFlavorName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "firstFlavorName", $$v)
                              },
                              expression: "form.firstFlavorName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Second Flavor Name:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "secondFlavorName",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "secondFlavorName",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.secondFlavorName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "secondFlavorName", $$v)
                              },
                              expression: "form.secondFlavorName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Max Frequency:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "maxFrequency",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "maxFrequency",
                              required: "",
                              type: "number",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.maxFrequency,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "maxFrequency", $$v)
                              },
                              expression: "form.maxFrequency"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Coefficient:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "coefficient",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "coefficient",
                              required: "",
                              type: "number",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.coefficient,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "coefficient", $$v)
                              },
                              expression: "form.coefficient"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "First Voltage:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "curveOne",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "voltage-one" },
                            _vm._l(_vm.temperatureList, function(item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "voltage-number" },
                                [_vm._v(" " + _vm._s(item.value) + " v ")]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "voltage-two",
                              staticStyle: { width: "100%" }
                            },
                            _vm._l(_vm.temperatureList, function(item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "voltage-list" },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus voltage-plus",
                                    on: {
                                      click: function($event) {
                                        return _vm.onVoltagePlus(index)
                                      }
                                    }
                                  }),
                                  _c("el-slider", {
                                    attrs: {
                                      vertical: "",
                                      step: _vm.step,
                                      min: _vm.min,
                                      max: _vm.max,
                                      height: "150px"
                                    },
                                    model: {
                                      value: item.value,
                                      callback: function($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value"
                                    }
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-minus voltage-reduce",
                                    on: {
                                      click: function($event) {
                                        return _vm.onVoltageReduce(index)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "voltage-three" },
                            _vm._l(8, function(index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "voltage-number" },
                                [_vm._v(" " + _vm._s(index) + "s ")]
                              )
                            }),
                            0
                          ),
                          _c("p", [
                            _vm._v(
                              " The Voltage curve is between 0.1v-4.2v, after the 8th second, the same as the 8th second "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "curveTwo"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "voltage-one" },
                            _vm._l(_vm.temperatureList1, function(item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "voltage-number" },
                                [_vm._v(" " + _vm._s(item.value) + " v ")]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "voltage-two",
                              staticStyle: { width: "100%" }
                            },
                            _vm._l(_vm.temperatureList1, function(item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "voltage-list" },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus voltage-plus",
                                    on: {
                                      click: function($event) {
                                        return _vm.onVoltagePlus1(index)
                                      }
                                    }
                                  }),
                                  _c("el-slider", {
                                    attrs: {
                                      vertical: "",
                                      step: _vm.step,
                                      min: _vm.min,
                                      max: _vm.max,
                                      height: "150px"
                                    },
                                    model: {
                                      value: item.value,
                                      callback: function($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value"
                                    }
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-minus voltage-reduce",
                                    on: {
                                      click: function($event) {
                                        return _vm.onVoltageReduce1(index)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "voltage-three" },
                            _vm._l(8, function(index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "voltage-number" },
                                [_vm._v(" " + _vm._s(index) + "s ")]
                              )
                            }),
                            0
                          ),
                          _c("p", [
                            _vm._v(
                              " The Voltage curve is between 0.1v-4.2v, after the 8th second, the same as the 8th second "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { lg: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { type: "reset" }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }