<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="8">
          <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
            <!-- 订单名称 -->
            <b-form-group
              label="Order Name:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="batchName"
              label-class="requiredRow"
            >
              <b-form-input
                id="batchName"
                v-model="form.batchName"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>
            <!-- 设备型号 -->
            <b-form-group
              label="Check Batch:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="check-batch"
              label-class="requiredRow"
            >
              <b-form-select
                id="check-batch"
                v-model="form.orderId"
                :options="types.batchOrder"
                value-field="id"
                text-field="batchName"
                required
                @change="changeBatchId(form.orderId)"
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              v-if="form.orderId"
              label="Check Flavor:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="check-flavor"
              label-class="requiredRow"
            >
              <b-form-input
                id="checkFlavor"
                v-model="flavorName"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
              v-if="form.orderId"
              label="Check Quantity:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="check-quantity"
              label-class="requiredRow"
            >
              <b-form-input
                id="checkQuantity"
                v-model="batchQuantity"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
              v-if="form.orderId"
              label="Start Scope:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="start-scope"
              label-class="requiredRow"
            >
              <b-form-input
                id="startScope"
                v-model="startScope"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
              v-if="form.orderId"
              label="End Scope:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="end-scope"
              label-class="requiredRow"
            >
              <b-form-input
                id="endScope"
                v-model="endScope"
                disabled
              ></b-form-input>
            </b-form-group>

            <!-- 口味一 -->
            <b-form-group
              label="First Flavor Name:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="firstFlavorName"
              label-class="requiredRow"
            >
              <b-form-input
                id="firstFlavorName"
                v-model="form.firstFlavorName"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- 口味二 -->
            <b-form-group
              label="Second Flavor Name:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="secondFlavorName"
              label-class="requiredRow"
            >
              <b-form-input
                id="secondFlavorName"
                v-model="form.secondFlavorName"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- 最大秒数 -->
            <b-form-group
              label="Max Frequency:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="maxFrequency"
              label-class="requiredRow"
            >
              <b-form-input
                id="maxFrequency"
                v-model="form.maxFrequency"
                required
                type="number"
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- 温度系数 -->
            <b-form-group
              label="Coefficient:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="coefficient"
              label-class="requiredRow"
            >
              <b-form-input
                id="coefficient"
                v-model="form.coefficient"
                required
                type="number"
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>
            <!-- 曲线一 -->
            <b-form-group
              label="First Voltage:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="curveOne"
              label-class="requiredRow"
            >
              <div class="voltage-one">
                <div
                  class="voltage-number"
                  v-for="(item, index) in temperatureList"
                  :key="index"
                >
                  {{ item.value }} v
                </div>
              </div>

              <div class="voltage-two" style="width: 100%">
                <!--               @touchmove.prevent="(e)=>{}" -->

                <div
                  class="voltage-list"
                  v-for="(item, index) in temperatureList"
                  :key="index"
                >
                  <i
                    class="el-icon-plus voltage-plus"
                    @click="onVoltagePlus(index)"
                  ></i>

                  <el-slider
                    v-model="item.value"
                    vertical
                    :step="step"
                    :min="min"
                    :max="max"
                    height="150px"
                  >
                  </el-slider>

                  <i
                    class="el-icon-minus voltage-reduce"
                    @click="onVoltageReduce(index)"
                  ></i>
                </div>
              </div>

              <div class="voltage-three">
                <div class="voltage-number" v-for="index of 8" :key="index">
                  {{ index }}s
                </div>
              </div>
              <p>
                The Voltage curve is between 0.1v-4.2v, after the 8th second,
                the same as the 8th second
              </p>
            </b-form-group>
            <!-- 曲线二 -->
            <b-form-group
              label-cols-lg="3"
              label-align-lg="right"
              label-for="curveTwo"
            >
              <div class="voltage-one">
                <div
                  class="voltage-number"
                  v-for="(item, index) in temperatureList1"
                  :key="index"
                >
                  {{ item.value }} v
                </div>
              </div>

              <div class="voltage-two" style="width: 100%">
                <!--               @touchmove.prevent="(e)=>{}" -->

                <div
                  class="voltage-list"
                  v-for="(item, index) in temperatureList1"
                  :key="index"
                >
                  <i
                    class="el-icon-plus voltage-plus"
                    @click="onVoltagePlus1(index)"
                  ></i>

                  <el-slider
                    v-model="item.value"
                    vertical
                    :step="step"
                    :min="min"
                    :max="max"
                    height="150px"
                  >
                  </el-slider>

                  <i
                    class="el-icon-minus voltage-reduce"
                    @click="onVoltageReduce1(index)"
                  ></i>
                </div>
              </div>

              <div class="voltage-three">
                <div class="voltage-number" v-for="index of 8" :key="index">
                  {{ index }}s
                </div>
              </div>
              <p>
                The Voltage curve is between 0.1v-4.2v, after the 8th second,
                the same as the 8th second
              </p>
            </b-form-group>

            <b-col lg="12" style="text-align: center">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" style="margin-left: 30px">Cancel</b-button>
            </b-col>
          </b-form>
        </b-col>
        <!-- end col -->
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import HistoryCurve from "@/components/HistoryCurve.vue";
/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    HistoryCurve,
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  created() {
    this.init();
  },
  data() {
    return {
      title: "Patch Voltage Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Patch Voltage Create",
          active: true,
        },
      ],
      types: {
        batchOrder: [{ batchName: "Please select", id: "" }],
      },
      form: {
        id: this.$route.query.id ? this.$route.query.id : "",
        batchName: "",
        orderId: "",
        firstFlavorName:"",
        secondFlavorName:"",
        maxFrequency:500,
        coefficient:100
      },
      flavorName: "",
      batchQuantity: "",
      startScope: "",
      endScope: "",
      temperatureList: [
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
      ],
      temperatureList1: [
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
      ],
      min: 0.1,
      max: 4.2,
      step: 0.1,
    };
  },
  methods: {
    init() {
      this.selectAllBatchNumber();
      if(this.$route.query.id ){
          this.findById(this.$route.query.id);
      } 
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.form.curveOne = this.temperatureList
        .map((item) => item.value * 1000)
        .join(",");
      this.form.curveTwo = this.temperatureList1
        .map((item) => item.value * 1000)
        .join(",");
      this.$api.productCheckOrder
        .saveProductCheckOrder(this.form)
        .then((res) => {
          if (res.success) {
            this.$router.go(-1);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
    },

    selectAllBatchNumber() {
      this.$api.productCheckOrder.selectAllBatchNumber({}).then((res) => {
        if (res.success && res.data) {
          this.types.batchOrder = this.types.batchOrder.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    changeBatchId(batchId) {
      if (batchId) {
        this.$api.productCheckOrder
          .selectProductCheckOrderById({
            id: batchId,
          })
          .then((res) => {
            if (res.success && res.data) {
              this.flavorName = res.data.flavorName;
              this.batchQuantity = res.data.batchQuantity;
              this.startScope = res.data.startScope;
              this.endScope = res.data.endScope;
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      }
    },

    findById(id){
      this.$api.productCheckOrder
          .findById({
            id: id,
          })
          .then((res) => {
            if (res.success && res.data) {
              this.flavorName = res.data.flavorName;
              this.batchQuantity = res.data.batchQuantity;
              this.startScope = res.data.startScope;
              this.endScope = res.data.endScope;
              this.form.batchName = res.data.batchName;
              this.form.orderId = res.data.orderId;
              this.form.firstFlavorName = res.data.firstFlavorName;
              this.form.secondFlavorName = res.data.secondFlavorName;
              this.form.secondFlavorName = res.data.secondFlavorName;
              this.form.coefficient = res.data.coefficient;
              var jsonArray = JSON.parse(res.data.curve);
              if (jsonArray.length > 1) {
                this.temperatureList = jsonArray[0].curveValue
                  .split(",")
                  .map((value) => ({ value: parseFloat(value) /1000}));
                this.temperatureList1 = jsonArray[1].curveValue
                  .split(",")
                  .map((value) => ({ value: parseFloat(value) /1000}));
              } else {
                this.temperatureList = jsonArray[0].curveValue
                  .split(",")
                  .map((value) => ({ value: parseFloat(value) /1000}));
              }
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss"  scoped>
.required-icon {
  ::before {
    content: "*";
  }
}

::v-deep .van-slider {
  margin: 10px auto;
}

.slider-button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffffff;
}

.voltage-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .voltage-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;

    .voltage-plus {
      margin-bottom: 20px;
    }

    .voltage-reduce {
      margin-top: 16px;
    }
  }
}
.voltage-one {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}
.voltage-three {
  margin-top: 66px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: hsla(0, 19%, 58%, 0);
  }
}
.temperature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-slider__button {
  border: 2px solid #86a1a9;
}
::v-deep .el-slider__bar {
  background: #86a1a9;
}
</style>